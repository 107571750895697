.flash-notice {
  margin-top: 40px;
  color: red;
  background: #fff;
}

.fixed-flash-notice {
  z-index: 999;
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  padding: 20px 20px;
  background: #FCEEC9;
  border-radius: 6px;
  
  div {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
  }
}

.form-error {
  color: red;
}

.user-image-tag {
  background-color: #4684f4;
  color: #fff;
  float: left;
  border-radius: 50%;
  border: 0;
  font-weight: bold;
  text-align: center;
  // font-size: 13px;
  line-height: 30px;
  width: 30px;
  height: 30px;

  &.small {
    line-height: 24px;
    width: 24px;
    height: 24px;  
  }
}
